<template>
    <div class="grant">
        <div class="logo">
          <a :href="website" target="_blank">
            <div class="grant-image" :style="{  backgroundImage: 'url(' + grantImage + ')' }">
            </div>
          </a>
        </div>
        <div class="label">
            {{this.grantData.attributes.title}}
        </div>
    </div>
</template>

<script>
export default {
  name: 'single-grant', 
  data: () => {
    return {
    }
  },
  props: {
    grantData: Object
  },
  methods: {
    readMore() {
      this.longDescription = !this.longDescription;
    }
  },
  computed: {
    website() {
      return this.grantData.attributes.field_grant_website ? this.grantData.attributes.field_grant_website.uri : '#'
    },
    grantImage() {
        return (process.env.VUE_APP_ENDPOINT + this.grantData.field_grant_logo.attributes.uri.url)
    }
  }, 
  mounted() {
    //console.log('grantData',this.grantData);
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/colors.scss";
.grants {
    .grant {
        border: 1px solid #E9E9E9;
        position: relative;
        .logo {
            display: flex; display: -webkit-flex;
            width: 290px;
            height: 290px;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            a {
              display: flex; display: -webkit-flex;
              width: 100%;
              height: 100%;
              align-items: center;
              justify-content: center;
            }
            .grant-image {
                width: 60%;
                height: 60%;
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
            }
        }
        .label {
            color: #333;
            text-align: center;
            font-weight: 500;
            padding: 20px 10px;
            font-size: 0.875rem;
            border-top: 1px solid #E9E9E9;
        }
    }
}
</style>
