<template>
    <div class="template-page">
        <basic-page :fulldata="fulldata" :pagedata="this.pageData" :bannerimg="this.bannerimg">
            <div v-if="pageData.body!=undefined" v-html="this.pageData.body[0].processed"></div>
                <div class="grants">
                    <single-grant v-for="(grant, index) in caseStudies" :key="index" :grantData="grant"></single-grant>
                </div>
        </basic-page>
    </div>
</template>

<script>
import SingleGrant from '../components/SingleGrant.vue'
import { fetchNodes, fetchSingleNode } from '../libs/drupalClient'
import BasicPage from './BasicPage.vue'

export default {
  components: { BasicPage, SingleGrant },
    name: 'grants',
    data: () => {
        return {
            currentPageId: "0c7e2120-ae28-4506-89c9-f1addbeb6fab",
            pageData: {},
            fulldata: {},
            caseStudies: null,
            bannerimg: null
        }
    },
    mounted() {
        fetchSingleNode('page', this.currentPageId, { include: ['field_banner_top','field_page_image', 'field_attachments'] })
            .then(res => {
                this.pageData = res[0].attributes
                this.fulldata = res[0]
                if(res[0].field_banner_top) this.bannerimg = process.env.VUE_APP_ENDPOINT + res[0].field_banner_top.attributes.uri.url
            })
        fetchNodes('grant', {
            include: ['field_grant_logo']
        })
        .then(res => {
            this.caseStudies = res
            //console.log("grants",this.caseStudies);
        })
    }
}
</script>

<style lang="scss" scoped>
@import "../styles/colors.scss";

.grants {
    display: flex; display: -webkit-flex;
    flex-wrap: wrap;
    margin-top: 2rem;
}
@media (max-width:768px){
    .grants {
        display: flex; display: -webkit-flex;
        flex-wrap: wrap;
        justify-content: center;
    }
}

</style>